import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from "antd";

import ArrowRightUpLine from "@/public/icons/ArrowRightUpLine.svg";
import IconChecks from "@/public/icons/Checks.svg";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useState, useEffect } from "react";
import resourceServices from "@/services/resources";
import registerServices from "@/services/registerSolution";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRouter } from "next/router";

const { Option } = Select;
export default function SectionContact() {
  const { t } = useTranslation("home");
  const router = useRouter();
  const [form] = Form.useForm();
  const layout = {
    wrapperCol: {
      span: 24,
    },
  };
  const [isSubmitedSuccess, setIsSubmitedSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryPhoneCode, setCountryPhoneCode] = useState("");

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    const countries = await resourceServices.fetchCountry();
    const currentLanguage = await resourceServices.fetchCurrentCountry();
    setCountries(countries);
    if (countries.length && currentLanguage) {
      const data = countries.find(
        (c) => c.country_code === currentLanguage.country_code
      );
      if (data) {
        setCountryPhoneCode(data.country_phone_code);
        form.setFieldValue("country_code", data.country_code);
      }
    }
  };

  const hanldeChangeCountry = (value) => {
    const phone = countries.find(
      (c) => c.country_code === value
    ).country_phone_code;
    setCountryPhoneCode(phone);
  };

  const hanldeChangeCountryPhoneCode = (value) => {
    setCountryPhoneCode(value);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async (values) => {
    setSubmitting(true);
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      setSubmitting(false);
      return;
    }
    executeRecaptcha("requestMeeting").then(async (gReCaptchaToken) => {
      try {
        const payload = {
          captcha_code: gReCaptchaToken,
          email: values.email || "",
          fullname: values.fullname || "",
          address: values.address || "",
          phone_number: values.phone_number
            ? `${countryPhoneCode}${values.phone_number}`
            : "",
          job_title: values.job_title || "",
          organization_name: values.organization_name || "",
          country_code: values.country_code || "",
          website: values.website || "",
          telegram_id: values.telegram_id || "",
          whatsapp_id: values.whatsapp_id || "",
          wechat_id: values.wechat_id || "",
          products: ["other"],
          source_link: window.location.href,
        };
        const firstLink = sessionStorage.getItem("first_link");
        if (firstLink) {
          payload.first_link = firstLink
        }
        await registerServices.register(payload);
        setSubmitting(false);
        setIsSubmitedSuccess(true);
        form.resetFields();
      } catch (error) {
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <section className="home-contact py-[100px] bg-[#0C0B11] overflow-y-hidden">
        <div className="new-container">
          <div className="flex justify-between gap-2 text-white md:flex-col">
            <div
              className={`flex-1 max-w-[580px] lg:max-w-[50%] md:max-w-full text-[20px] `}
            >
              <h2 className="home-contact__title text-[61px] lg:text-[47px] lg:leading-[64px] leading-[75px] mb-6">
                <span className={`text-[#8C8C8C] mr-0`}>
                  {t("contact.title_1")}{" "}
                  {router.locale === "en" ? (
                    <span className="inline-block mr-1 -ml-2">,</span>
                  ) : null}
                </span>
                <span className="font-cystack">{t("contact.title_2")}</span>
              </h2>
              <p className="mb-1 text-[20px] leading-[30px] text-[#D9D9D9] max-w-[450px]">
                {t("contact.desc")}
              </p>
            </div>
            <div className="w-full max-w-[540px] lg:max-w-[50%] md:max-w-full relative home-contact-form">
              <div className="w-full h-full p-8 md:py-5 md:px-3 relative z-[1] bg-white">
                <Form
                  disabled={isSubmitedSuccess || submitting}
                  {...layout}
                  form={form}
                  onFinish={onFinish}
                  name="book-meesting-form"
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("validate.input_required", {
                          ns: "common",
                          field: t("form.company_name", { ns: "common" }),
                        }),
                      },
                    ]}
                    className="mb-[14px]"
                    name="organization_name"
                  >
                    <Input
                      size="small"
                      placeholder={t("form.company_name", { ns: "common" })}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("validate.select_required", {
                          ns: "common",
                          field: t("form.country", { ns: "common" }),
                        }),
                      },
                    ]}
                    className="mb-[14px]"
                    name="country_code"
                  >
                    <Select
                      onChange={hanldeChangeCountry}
                      filterOption={(input, option) =>
                        (option?.children)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder={t("form.country", { ns: "common" })}
                      showSearch
                      allowClear
                      className="h-12"
                    >
                      {countries.map((item) => (
                        <Option
                          key={item.country_code}
                          value={item.country_code}
                        >
                          {item.country_name || ""}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item className="mb-[14px]" name="address">
                    <Input
                      size="small"
                      placeholder={t("form.address", { ns: "common" })}
                    />
                  </Form.Item>
                  <Form.Item name="website" className="mb-[14px]">
                    <Input
                      size="small"
                      placeholder={t("form.website", { ns: "common" })}
                    />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t("validate.input_required", {
                              ns: "common",
                              field: t("form.fullname", { ns: "common" }),
                            }),
                          },
                        ]}
                        className="mb-[14px]"
                        name="fullname"
                      >
                        <Input size="small" placeholder="Full name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t("validate.input_required", {
                              ns: "common",
                              field: t("form.job_title", { ns: "common" }),
                            }),
                          },
                        ]}
                        className="mb-[14px]"
                        name="job_title"
                      >
                        <Input
                          size="small"
                          placeholder={t("form.job_title", { ns: "common" })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("validate.input_required", {
                          ns: "common",
                          field: t("form.email", { ns: "common" }),
                        }),
                      },
                      {
                        type: "email",
                        message: t("validate.invalid", {
                          ns: "common",
                          field: t("form.email", { ns: "common" }),
                        }),
                      },
                    ]}
                    className="mb-[14px]"
                    name="email"
                  >
                    <Input
                      size="small"
                      placeholder={t("form.email", { ns: "common" })}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("validate.input_required", {
                          ns: "common",
                          field: t("form.phone", { ns: "common" }),
                        }),
                      },
                      {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: t("validate.invalid", {
                          ns: "common",
                          field: t("form.phone", { ns: "common" }),
                        }),
                      },
                    ]}
                    className="mb-[14px]"
                    name="phone_number"
                  >
                    <Space.Compact className="w-full">
                      <Select
                        value={countryPhoneCode}
                        showSearch
                        onChange={hanldeChangeCountryPhoneCode}
                        className="max-w-[110px] h-12"
                        placeholder="Phone number "
                      >
                        {countries.map((item) => (
                          <Option
                            key={item.country_code}
                            value={item.country_phone_code}
                          >
                            {item.country_phone_code || ""}
                          </Option>
                        ))}
                      </Select>
                      <Input
                        name="phone"
                        className="w-full"
                        placeholder={t("form.phone", { ns: "common" })}
                      />
                    </Space.Compact>
                  </Form.Item>
                  <Row gutter={14} className="mb-[14px]">
                    <Col span={8}>
                      <Form.Item name="telegram_id" className="mb-0">
                        <Input size="small" placeholder="Telegram" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="whatsapp_id" className="mb-0">
                        <Input size="small" placeholder="WhatsApp" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="wechat_id" className="mb-0">
                        <Input size="small" placeholder="Wechat" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(t("validate.agree", { ns: "common" }))
                              ),
                      },
                    ]}
                    name="agreement"
                    valuePropName="checked"
                    className="mb-8"
                  >
                    <Checkbox className="sm:whitespace-normal whitespace-nowrap">
                      {t("form.agree", { ns: "common" })}
                      <Link href="/terms-of-service">
                        <span className="ml-1 text-[#1890FF]">
                          {t("shared.terms_of_service", { ns: "common" })}
                        </span>
                      </Link>
                      <span className="mx-1">
                        {t("shared.and", { ns: "common" })}
                      </span>
                      <Link href="/privacy">
                        <span className="text-[#1890FF]">
                          {t("shared.privacy", { ns: "common" })}
                        </span>
                      </Link>
                      <span>{t("form.of_cystack", { ns: "common" })}</span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    {isSubmitedSuccess ? (
                      <Button
                        className="flex items-center justify-center gap-2 py-3 text-white rounded-none text-body1sb bg-neutral-dark hover:border-neutral-dark"
                        disabled
                        size={"large"}
                        block
                      >
                        <IconChecks className="w-6 h-6" />
                        <span>
                          {t("button.submited_success", { ns: "common" })}
                        </span>
                      </Button>
                    ) : (
                      <Button
                        loading={submitting}
                        size={"large"}
                        className="flex items-center justify-center gap-2 py-3 text-white rounded-none text-body1sb bg-neutral-dark hover:border-neutral-dark"
                        htmlType="submit"
                        block
                      >
                        <span>{t("contact.book_meeting")}</span>
                        <ArrowRightUpLine />
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
