import { Button } from "antd";
import ArrowRight from "@/public/icons/ArrowRight.svg";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export default function Banner() {
  const { t } = useTranslation("home");
  return (
    <>
      <section className="home-banner bg-black overflow-hidden pt-[96px]">
        <div className="relative new-container pb-[550px] xl:pb-[400px] md:pb-[300px] sm:pb-[220px]">
          <h1 className="mb-6 text-[72px] leading-[90px] lg:text-[61px] lg:leading-[75px] md:text-[47px] md:leading-[64px] text-white text-center font-cystack">
            {t("banner.title")}
          </h1>
          <p className="text-[#D9D9D9] text-[20px] max-w-[860px] mx-auto leading-[30px] md:text-[18px] mb-8 text-center">
            {t("banner.desc")}
          </p>
          <div className="flex items-center justify-center gap-4">
            <Link href="/products">
              <Button size={"large"} className="flex items-center px-8">
                <span>{t("button.start_now", { ns: "common" })}</span>
              </Button>
            </Link>
            <Link href="/contact-sales">
              <Button
                type="default"
                size={"large"}
                className="flex items-center px-8 text-white bg-transparent border-transparent hover:border-white"
              >
                <span>{t("button.contact_us", { ns: "common" })}</span>
                <ArrowRight className="w-6 h-6 ml-2 !text-white" />
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
