import { useTranslation } from "next-i18next";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

export default function SectionPlatform() {
  const { t } = useTranslation("platform");

  const AttackManagement = () => {
    return (
      <div className="w-fit">
        <h5 className="tracking-[2px] uppercase text-[16px] font-bold leading-6 text-white md:text-[11px] sm:text-[5px] sm:mb-2 text-center mb-6 relative z-[1] xl:text-[14px] whitespace-nowrap">
          attack surface management
        </h5>
        <div className="flex items-center gap-3 sm:gap-1">
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              attack surface management
            </div>
          </div>
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              Vulnerability Management
            </div>
          </div>
        </div>
      </div>
    );
  };
  const SecurityAssessment = () => {
    return (
      <div className="w-fit">
        <h5 className="tracking-[2px] uppercase text-[16px] font-bold leading-6 text-white md:text-[11px] sm:text-[5px] sm:mb-2 text-center mb-6 relative z-[1] xl:text-[14px] whitespace-nowrap">
          security assessment
        </h5>
        <div className="flex items-center gap-3 sm:gap-1">
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              Audit Project management
            </div>
          </div>
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              bug bounty program
            </div>
          </div>
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              vulnerability scan
            </div>
          </div>
        </div>
      </div>
    );
  };
  const SecurityControls = () => {
    return (
      <div className="w-fit">
        <h5 className="tracking-[2px] uppercase text-[16px] font-bold leading-6 text-white md:text-[11px] sm:text-[5px] sm:mb-2 text-center mb-6 relative z-[1] xl:text-[14px] whitespace-nowrap">
          security controls
        </h5>
        <div className="flex items-center gap-3 sm:gap-1">
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              data leak detection
            </div>
          </div>
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              password & secret manager
            </div>
          </div>
          <div className="flex items-center justify-center box-container">
            <div className="box-container__inner px-3 md:text-[8px] md:leading-3 sm:text-[4px] sm:leading-[8px] flex lg:text-[10px] items-center justify-center text-[14px] font-semibold text-white xl:text-[12px] xl:px-2 leading-5 capitalize text-center">
              trust center
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="home-platform bg-[#0C0B11]">
        <div className="new-container relative py-[120px] xl:py-20 lg:py-10">
          <div className="filter-blur"></div>
          <div className="absolute inset-0">
            <Lottie loop play path="/jsons/line-bg.json" className="w-full" />
          </div>
          <div className="max-w-[1270px] mx-auto">
            <div className="flex items-center justify-center gap-10 xl:gap-9 lg:gap-2">
              <AttackManagement />
              <SecurityAssessment />
              <SecurityControls />
            </div>
            <div className="h-[150px] md:h-[120px] sm:h-[100px] max-w-[1140px] xl:max-w-[970px] lg:max-w-[780px] md:max-w-[580px] sm:max-w-[85%] -mt-2 xl:-mt-4 lg:-mt-7 mx-auto relative z-[1]">
              <Lottie
                loop
                play
                path="/jsons/line-dot.json"
                className="h-full"
              />
            </div>
            <div className="relative flex items-center justify-center w-full box-logo z-[2] -mt-2 xl:-mt-3 lg:-mt-6">
              <div className="box-logo__inner">
                <div className="w-[410px] h-[50px]">
                  <Lottie
                    loop
                    play
                    path="/jsons/logo.json"
                    className="h-full"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-12 gap-10 xl:gap-5 lg:gap-2 md:gap-0 md:mt-6 relative z-[1] max-w-full">
              <img
                src="/images/home/platform/Platform1.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
              <img
                src="/images/home/platform/Platform2.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
              <img
                src="/images/home/platform/Platform3.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
              <img
                src="/images/home/platform/Platform4.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
              <img
                src="/images/home/platform/Platform5.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
              <img
                src="/images/home/platform/Platform6.svg"
                className="h-[100px] max-w-[15%]"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
