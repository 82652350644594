import { Button } from "antd";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import ArrowRight from "@/public/icons/ArrowRight.svg";

export default function SectionCommonPartner() {
  const { t } = useTranslation("home");
  return (
    <>
      <div className="py-20 md:py-10 common-section-partner">
        <h2 className="uppercase text-[12px] leading-[24px] tracking-[2px] text-center text-[#858585] mb-12">
          {t("partner.title")}
        </h2>
        <div className="container relative common-section-partner__list">
          <div className="carousel__wrapper right-to-left flex items-center justify-center h-[100px]">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
              (item, index) => (
                <div key={index * 2} className="carousel__slide">
                  <img
                    src={`/images/partner/partner${item}.svg`}
                    className="object-contain max-w-full max-h-[110px]"
                    alt="CyStack partner logo cake"
                  />
                </div>
              )
            )}
          </div>
          <div className="carousel__wrapper left-to-right flex items-center justify-center h-[100px]">
            {[
              10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 10, 11, 12, 13, 14, 15,
              16, 17, 18, 19,
            ].map((item, index) => (
              <div key={index * 10} className="carousel__slide">
                <img
                  src={`/images/partner/partner${item}.svg`}
                  className="object-contain max-w-full max-h-[110px]"
                  alt="CyStack partner logo cake"
                />
              </div>
            ))}
          </div>
        </div>
        <Link href={"/projects"}>
          <Button
            type={"link"}
            className="flex items-center gap-1 mx-auto leading-6 text-[16px] mt-12 text-dark-title hover:text-primary-500"
          >
            <span>{t("button.view_all", { ns: "common" })}</span>
            <ArrowRight className="w-5 h-5 " />
          </Button>
        </Link>
      </div>
    </>
  );
}
