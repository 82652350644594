import * as Sentry from "@sentry/nextjs";

import { getCookie, setCookie } from "cookies-next";

import Banner from "@/components/home/Banner";
import SectionBlog from "@/components/home/Blog";
import SectionContact from "@/components/home/Contact";
import SectionFeatures from "@/components/home/Features";
import SectionPartner from "@/components/Partner";
import Testimonial from "@/components/Testimonial";
import SectionWhyUs from "@/components/home/WhyUs";
import blogServices from "@/services/blog";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import resourceService from "../services/resources";
import SectionPlatform from "@/components/home/Platform";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
export async function getServerSideProps({ locale }) {
  try {
    const responseTag = await blogServices.fetchBlogTags({ slug: locale });

    const params = {
      _embed: 1,
      page: 1,
      per_page: 3,
      tags: responseTag[0].id,
    };
    const response = await blogServices.fetchPosts(params);
    const resources = response.data.map((post) => {
      const thumbnail_link =
        post?._embedded["wp:featuredmedia"]?.[0]?.source_url;

      if (thumbnail_link) {
        return {
          ...post,
          thumbnail_link,
        };
      }
      return {
        ...post,
        thumbnail_link: "/BlogThumbnail.png",
      };
    });

    return {
      props: {
        posts: resources,
        locale,
        ...(await serverSideTranslations(locale)),
      },
    };
  } catch (error) {
    Sentry.captureMessage(error);
    return {
      props: {
        documents: [],
        posts: [],
        locale,
        ...(await serverSideTranslations(locale)),
      },
    };
  }
}

export default function Home({ posts, documents, locale }) {
  const { t } = useTranslation("home");
  const { i18n } = useTranslation();
  const router = useRouter();
  useEffect(() => {
    if (getCookie("is_visited")) {
      return;
    }
    resourceService
      .fetchCurrentCountry()
      .then((response) => {
        i18n.changeLanguage(response.language);
        setCookie("is_visited", true, { maxAge: 99999999 });
        router.push(router.pathname, router.asPath, {
          locale: response.language,
        });
      })
      .catch((error) => {
        Sentry.captureMessage(error);
      });
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "body",
        nonce: undefined,
      }}
    >
      <Head>
        <title>{t("seo.title")}</title>
        <meta name="title" content={t("seo.title")} />
        <meta name="description" content={t("seo.desc")} />

        <meta property="og:title" content={t("seo.title")} />
        <meta property="og:description" content={t("seo.desc")} />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}${
            useRouter().locale === "vi" ? "/vi" : ""
          }${useRouter().asPath}`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />

        <meta name="twitter:title" content={t("seo.title")} />
        <meta name="twitter:description" content={t("seo.desc")} />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />
      </Head>

      <Banner />

      <section className="bg-black">
        <div className="new-container">
          <div className="w-full"></div>
          <div className="flex justify-center py-16 md:flex-col md:justify-start md:items-center ">
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/user.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.customer")}
              </p>
            </div>
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/solution.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.security")}
              </p>
            </div>
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/customer.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.user")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <SectionFeatures />
      <section className="home-partner">
        <SectionPartner />
      </section>

      <SectionPlatform />

      <SectionWhyUs />

      <Testimonial />

      <SectionContact />

      <SectionBlog posts={posts} />
    </GoogleReCaptchaProvider>
  );
}
