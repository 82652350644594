import WhyUsBag from "@/public/icons/WhyUsBag.svg";
import WhyUsCode from "@/public/icons/WhyUsCode.svg";
import WhyUsHeart from "@/public/icons/WhyUsHeart.svg";
import WhyUsProven from "@/public/icons/WhyUsProven.svg";
import { useTranslation } from "next-i18next";

export default function SectionWhyUs() {
  const { t } = useTranslation("home");

  const resourceData = [
    {
      title: t("why_us.item_1.title"),
      description: t("why_us.item_1.desc"),
      icon: <WhyUsCode className="w-6 h-6 text-white" />,
    },
    {
      title: t("why_us.item_2.title"),
      description: t("why_us.item_2.desc"),
      icon: <WhyUsProven className="w-6 h-6 text-white" />,
    },
    {
      title: t("why_us.item_3.title"),
      description: t("why_us.item_3.desc"),
      icon: <WhyUsBag className="w-6 h-6 text-white" />,
    },
    {
      title: t("why_us.item_4.title"),
      description: t("why_us.item_4.desc"),
      icon: <WhyUsHeart className="w-6 h-6 text-white" />,
    },
  ];
  return (
    <>
      <section className="overflow-hidden bg-white why-us">
        <div className="new-container border-b border-[#EAECF0] border-solid py-[96px] md:py-10 border-t-0 border-l-0 border-r-0">
          <p className="text-center text-[#737CFF] text-body3sb mb-4">
            {t("why_us.subtitle")}
          </p>
          <h2 className="max-w-[858px] mx-auto text-dark-title mb-16 text-[61px] lg:text-[47px] lg:leading-[64px] sm:mb-6 text-center leading-[73px]">
            {t("why_us.title")}
          </h2>
          <div className="grid grid-cols-4 gap-8 px-8 xl:gap-6 md:gap-4 xl:px-0 xl:grid-cols-2 sm:grid-cols-1">
            {resourceData.map((item, index) => (
              <div className="flex flex-col gap-6" key={index}>
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-md bg-primary-500">
                  {item.icon}
                </div>
                <div className="flex-grow">
                  <h3
                    className={`text-[27px] leading-[36px] font-semibold mb-3 text-dark-title`}
                  >
                    {item.title}
                  </h3>
                  <p className="text-[16px] leading-6 text-grey">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
