import ENDPOINT from "@/config/endpoint";
import request from "@/utils/request";

async function fetchCurrentCountry() {
  return await request({
    url: ENDPOINT.RESOURCE_LANG,
    method: "get",
  });
}

async function fetchCountry(params) {
  return await request({
    url: ENDPOINT.COUNTRIES,
    method: "get",
    params,
  });
}

async function fetchProduct(params) {
  return await request({
    url: ENDPOINT.PRODUCTS,
    method: "get",
    params,
  });
}

async function fetchIndustry(params) {
  return await request({
    url: ENDPOINT.INDUSTRY,
    method: "get",
    params,
  });
}

async function fetchProjectStatus(params) {
  return await request({
    url: ENDPOINT.PROJECT_STATUS,
    method: "get",
    params,
  });
}

async function fetchVulnPlugin(params) {
  return await request({
    url: ENDPOINT.RESOURCE_VULN_PLUGINS,
    method: "get",
    params
  });
}

async function fetchVulnPluginFilters(params) {
  return await request({
    url: ENDPOINT.RESOURCE_VULN_PLUGINS_FILTERS,
    method: "get",
    params
  });
}

async function fetchVulnPluginById(id) {
  return await request({
    url: `${ENDPOINT.RESOURCE_VULN_PLUGINS}/${id}`,
    method: "get",
  });
}

async function fetchInsudtry() {
  return await request({
    url: `${ENDPOINT.RESOURCE_INDUSTRIES}`,
    method: "get",
  });
}

export default {
  fetchCurrentCountry,
  fetchCountry,
  fetchProduct,
  fetchIndustry,
  fetchProjectStatus,
  fetchVulnPlugin,
  fetchVulnPluginById,
  fetchVulnPluginFilters,
  fetchInsudtry
};